import React from "react"
import { Link } from "gatsby"

export default () => (
    <div className="sideContent">
        <div id="sidebar">
            <div className="inner">
                <div className="sidebarIntro">
                    <h4>
                        事業を行う上で、<br />
                        資金調達は最も重要な戦略です。
                    </h4>
                    <Link to={`/online/`} className="sidebarLink">詳しくはこちら ＞</Link>
                </div>
                <div className="sideBarList">
                    <ul>
                        <li>・借金はしないと起業時に決めている。</li>
                        <li>・資金が足りなくなったらしようと思う。</li>
                        <li>・今はあるからまだ大丈夫かな・・</li>
                    </ul>
                </div>
                <div className="sideBarImg"><img src="/images/sideBarImg.svg" alt="" /></div>            
                <div className="sidebarSS">
                    <div className="inner">
                        <p>
                            融資の場合、金融機関がお金を貸すためには<br />審査があります。あなたが金融機関でしたら、<br />資金が足りなくなってから来る人や業績が落ち込んできた人に貸したいと思うでしょうか？
                        </p>
                    </div>
                </div>
                <div className="sideBarLinebutton">
                    <Link to={`/contact/`}>問い合わせる</Link>
                </div>
            </div>
        </div> 
    </div>   
)