import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import CircleE from "./circleE"


const RandomPosts = props => {

  const data = useStaticQuery(graphql`
    query {
        allContentfulBlogPost(
            sort: {fields: createdAt, order: DESC}
            filter: { category: { elemMatch: { id: { eq: "9845a7e9-6302-59c9-95de-66dde10b25e5" } } } }
            skip: 0
            limit: 3
            ) {
              nodes {
                id
                title
                slug
                publishDate
                publishDateJP: publishDate(formatString: "YYYY/MM/DD")
                category {
                  category
                  categorySlug
                  id
                }
                tags {
                  id
                  tagTitle
                  tagSlug
                }
                eyecatch {
                  fluid(maxWidth: 500) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                  description
                }
              }
          }
    }
  `)

  const relatedPosts = data.allContentfulBlogPost.nodes.filter(
        node => node.id !== props.id
  );

  if (!relatedPosts.length) {
    return null;
  }

  return (
    <section class="topShikin topArticleList">
      <div class="inner">
          <h2><img src="/images/shikintyotatu.svg" alt="事例紹介" /></h2>
          <h3>資金調達と一言にいっても方法は色々あります。<br />目的により方法を選択したり、組み合わせたりして<br />自分に合った最適な方法で資金調達しましょう。</h3>
          <div class="uploadList">
          {relatedPosts.map(node => (
              <Link to={`/${node.slug}/`}>
                <div className="uploadListItem" key={node.id}>
                    <figure className="articleImg">
                        <Img
                            fluid={node.eyecatch.fluid}
                            alt={node.eyecatch.description}
                        />
                    </figure>
                    <div className="articleDetail">
                        <div className="articleTitle">{node.title}</div>
                        {node.tags.map(tag => (
                                    <div id="articleTab" className={tag.tagSlug} key={tag.id}><p>{tag.tagTitle}</p></div>
                        ))}
                    </div>
                  </div>
              </Link>
            ))}
          </div>
      </div>
      <CircleE />
    </section>
  )
}

export default RandomPosts