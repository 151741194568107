import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import CircleF from "./circleF"

const RandomPosts = props => {

  const data = useStaticQuery(graphql`
    query {
        allContentfulBlogPost(
            sort: {fields: createdAt, order: DESC}
            filter: { category: { elemMatch: { id: { eq: "a072a16a-32cf-5bab-9f44-b57c853dcb12" } } } }
            skip: 0
            limit: 3
            ) {
              nodes {
                id
                title
                slug
                publishDate
                publishDateJP: publishDate(formatString: "YYYY/MM/DD")
                category {
                  category
                  categorySlug
                  id
                }
                tags {
                  id
                  tagTitle
                  tagSlug
                }
                eyecatch {
                  fluid(maxWidth: 500) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                  description
                }
              }
          }
    }
  `)

  const relatedPosts = data.allContentfulBlogPost.nodes.filter(
        node => node.id !== props.id
  );

  if (!relatedPosts.length) {
    return null;
  }

  return (
    <section class="topSogyomae topArticleList">
      <div class="inner">
          <h2><img src="/images/sogyomae.svg" alt="事例紹介" /></h2>
          <div class="uploadList">
          {relatedPosts.map(node => (
              <Link to={`/${node.slug}/`}>
                <div className="uploadListItem" key={node.id}>
                    <figure className="articleImg">
                        <Img
                            fluid={node.eyecatch.fluid}
                            alt={node.eyecatch.description}
                        />
                    </figure>
                    <div className="articleDetail">
                        <div className="articleTitle">{node.title}</div>
                    </div>
                  </div>
              </Link>
            ))}
          </div>
      </div>
      <CircleF />
    </section>
  )
}

export default RandomPosts